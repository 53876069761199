<template>
  <div class="px-4 pt-[calc(60px-16px)] sm:px-0 lg:pt-[calc(120px-16px)]">
    <div
      class="mx-auto flex w-full max-w-[600px] flex-col gap-8 rounded-[13px] bg-navy px-4 py-5 md:px-14 md:py-8"
    >
      <div class="text-center font-Kontesa text-h2-mobile font-bold text-white">
        as seen In
      </div>

      <div class="flex gap-2 md:gap-3">
        <div
          v-for="item in news"
          :key="item.id"
          class="flex h-[45px] w-[74px] basis-full items-center justify-center rounded-[9px] bg-white md:h-[62px] md:rounded-2xl"
        >
          <NuxtImg
            :src="item.image"
            :width="item.width"
            :height="item.height"
            :alt="item.id"
            :class="item.imgClass"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const news = [
  {
    image: "/images/for-business-page/news/npr.svg",
    id: "npr",
    width: 42,
    height: 14,
    imgClass: "h-[10px] w-[28px] sm:w-[42px] sm:h-[14px]",
  },
  {
    image: "/images/for-business-page/news/yahoo.png",
    id: "yahoo",
    width: 197,
    height: 62,
    imgClass: "h-[11px] w-[32px] sm:w-[49px] sm:h-[15px]",
  },
  {
    image: "/images/for-business-page/news/forbes.png",
    id: "forbes",
    width: 182,
    height: 57,
    imgClass: "h-[11px] w-[34px] sm:w-[45px] sm:h-[14px]",
  },
  {
    image: "/images/for-business-page/news/business-insider.png",
    id: "insider",
    width: 219,
    height: 68,
    imgClass: "w-[41px] h-[12px] sm:w-[54px] sm:h-[17px]",
  },
];
</script>
